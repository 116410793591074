<template>
    <div class="m-5">
      <div class="row mt-5">
        <!-- <div class="col-md-12 col-lg-4 p-0">
          <h4>
            Sviluppo di una piattaforma digitale fortemente innovativa, fornendo
            all’utente finale la possibilità di chiedere direttamente online
            servizi, consulenze, ausili medici e tutto quanto venga rilevato come
            necessario, al fine di rispondere ad esigenze reali date.
          </h4>
        </div> -->
        <div class="col-md-12 col-lg-12 text-xl pl-4 pr-lg-4">
          <p>
            <strong> CONDIZIONI GENERALI DI CONTRATTO</strong> <br><br>
                <b>	1. PREMESSE </b><br>
                Le condizioni generali di contratto regolano l'accesso e l'utilizzo della piattaforma “Aiutofamigle”.
                Aiutofamigle è una piattaforma web di proprietà esclusiva di My Care S.r.l.<br>
                - Per “cliente” s’intende il soggetto che richiede una prestazione lavorativa tra quelle disponibili  tramite piattaforma.<br>
                - Per “lavoratore” s’intende il soggetto che effettua la prestazione lavorativa;<br>
                - Per “utente” s’intente il lavoratore e/o cliente che registrandosi usufruisce dei servizi della piattaforma “Aiutofamigle”.<br>
                <b>2. OGGETTO</b> <br>
                La piattaforma virtuale “Aiutofamigle” permette di commercializzare servizi di assistenza alla famiglia (esempio: assistente domiciliare, babysitter, colf, dogsitter, etc.). La piattaforma mette in contatto i clienti che hanno necessità di utilizzare i servizi presenti in piattaforma, con persone fisiche che offrono la loro attività lavorativa. “Aiutofamigle” non interferisce in alcun modo, nè controlla le modalità esecutive delle attività prestate dai lavoratori ai clienti. Nei confronti dei clienti la piattaforma web “Aiutofamigle” opera come un mandatario con rappresentanza e, a fronte della possibilità di utilizzare i servizi offerti dalla piattaforma web “Aiutofamigle”, addebita agli stessi una percentuale sul transato del singolo servizio richiesto e previsto nel tariffario generale contenuto nella piattaforma. <br>
                <b>3. REGISTRAZIONE</b> <br>
                Per poter uti utilizzare i servizi della piattaforma, gli utenti (clienti e lavoratori) devono accedere alla piattaforma ed effettuare la registrazione creando un proprio account. Durante il processo di registrazione accettano integralmente i presenti termini di condizioni generali, contestualmente alla privacy consultabile sul sito Internet della piattaforma. Gli utenti assicurano che i dati personali, forniti in sede di registrazione, sono veritieri, completi e corretti. La piattaforma non potrà in alcun modo essere ritenuta responsabile delle informazioni comunicate dagli utenti che potrebbero risultare erronee o fraudolenti. Possono essere utenti solo coloro che abbiano compiuto il diciottesimo anno di età. La registrazione degli utenti si conclude nel momento in cui gli stessi ne danno conferma attraverso il link di attivazione presente nella comunicazione di verifica inviata dalla piattaforma. La registrazione si conclude soltanto in seguito alla comunicazione delle credenziali per l'utilizzo della piattaforma inviate dallo staff. <br>
                <b>4. TIPOLOGIA DEL SERVIZIO</b> <br>
                È possibile scegliere il servizio su prenotazione. La richiesta deve essere effettuata almeno 24ore prima. L’accettazione della richiesta di servizio da parte del lavoratore è subordinata alla comunicazione di accettazione inviata dalla piattaforma al cliente. Il cliente, al momento della prenotazione, accetta l'importo indicato in preventivo e provvede al pagamento dello stesso attraverso il sistema di pagamento online della piattaforma. Il pagamento da parte del cliente rende disponibile il servizio. 
                <b>5. PAGAMENTO</b> <br>
                “Aiutofamigle” si serve di un sistema di elaborazione di pagamento esterno che collega la carta di credito o carta prepagata del cliente alla piattaforma per l'elaborazione dei pagamenti “Aiutofamigle” non sarà ritenuta responsabile di qualsivoglia errore imputabile al sistema di elaborazione dei pagamenti.<br> 
                Aiutofamigle si riserva la facoltà di apportare delle modifiche alle tariffe su base periodica. E’ onere dell'utente informarsi per proprio conto sulle tariffe correnti al momento della prenotazione del servizio.<br>
                <b>6. ANNULLAMENTO DEL SERVIZIO</b> <br>
                I clienti che hanno intenzione di cancellare una prenotazione devono seguire le procedure di cancellazione che ricevono nella comunicazione di prenotazione del servizio.<br>
                Qualora il cliente effettui la cancellazione della prenotazione, “Aiutofamigle” si riserva il diritto di addebitare al cliente una somma a seconda delle tempistiche di disdetta della richiesta.<br>
                <b>7.MANLEVA E RESPONSABILITA’</b> <br>
                Accettando i termini di condizioni generali ed utilizzando il servizio, l'utente (sia esso lavoratore o cliente) manleva “Aiutofamigle” nonché i suoi soci, amministratori, dipendenti e collaboratori, da qualsiasi richiesta di risarcimento, danno, costo, perdita, spese e oneri che siano derivati da violazioni o inadempienze da parte dell’utente.<br> 
                “Aiutofamigle” non assume alcuna responsabilità circa la continuità del funzionamento della piattaforma e si riserva ogni diritto alla sua interruzione sospensione temporanea o definitiva con e senza preavviso. Pertanto, in caso di interruzione, sospensione o malfunzionamento gli utenti non hanno diritto ad alcun risarcimento e/o indennità.<br>
                “Aiutofamigle” non risponde dell'eventuale utilizzo illecito della piattaforma da parte dei propri utenti e non sarà ritenuta responsabile dei danni derivanti da guasto o ritardo nell’elaborazione della richiesta. <br>
                “Aiutofamigle” non interferisce, non controlla e non risponde in alcun modo delle modalità esecutive delle attività prestate dai lavoratori ai clienti. Gli utenti agiscono sotto la propria esclusiva e completa responsabilità per il servizio richiesto. <br>
                È esclusa quindi, nella misura più ampia ed esaustiva, la responsabilità “Aiutofamigle” per qualsivoglia danno diretto o indiretto subito dagli utenti o da terzi in occasione dell'erogazione del servizio. <br>
                <b>8. DIRITTO D’AUTORE E LICENZA D’USO</b> <br>
                Tutte le informazioni i contenuti e i materiali resi disponibili sulla piattaforma “Aiutofamigle”, inclusi a titolo esemplificativo il logo, i testi, i marchi, ecc sono di proprietà esclusiva di MyCare s.r.l. <br>
                <b>9. PRIVACY POLICY</b> <br>
                Le condizioni di gestione della privacy sono disciplinate nel testo contenuto sulla piattaforma Aiutofamigle <br>
                <button @click="downloadFile">Scarica L'informativa</button><br>
                <b>10. FORO COMPETENTE</b> <br>
                In caso di controversia sarà competente in via esclusiva il Tribunale di Milano <br>
          </p>
        </div>
      </div>
    </div>
</template>
<script>
    export default {
    methods: {
        downloadFile() {
        // For a static file
        const link = document.createElement('a');
        link.href = '/Informativa.pdf'; // Adjust the path to where your file is located
        link.setAttribute('download', 'Informativa.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // If you need to handle a dynamic file, you might need to first generate the file
        // and then perform similar steps to initiate the download.
        }
    }
    }
</script>
<style>
    .text-xl {
    font-size: 1rem;
    }
    button {
    padding: 10px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    }
    button:hover {
    background-color: darkblue;
    }
</style>